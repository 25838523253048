import OktaAuth from '@okta/okta-auth-js';

export enum OktaProfile {
  Partner = 'partner',
  Residential = 'residential',
}

export enum OktaAuthPathState {
  Started = 'started',
  Ended = 'ended',
}

export type OktaState = {
  isProcessing: boolean;
  isAuthenticated: boolean;
  isInitialized?: boolean;
  isDisabled?: boolean;

  forbidden?: boolean;

  profile?: OktaProfile;
  oktaAuthPathState?: OktaAuthPathState;

  userName?: string;
  firstName?: string;
  lastName?: string;
};

export type OktaAuthListener = (newState: OktaState, oldState: OktaState) => void;
export type OktaAuthSubscription = {
  remove: () => void;
};

type AuthConfig = {
  issuer: string;
  clientId: string;
};

const PartnerOktaAuthDev: AuthConfig = {
  issuer: 'https://partnerlogin.uat.viasat.com/oauth2/aus32t7p0yB1XVScv1d7',
  clientId: '0oahy06qej0rNAue71d7',
};

const CustomerOktaAuthDev: AuthConfig = {
  issuer: 'https://login.uat.viasat.com/oauth2/aus2euitmtjYDBklu1d7',
  clientId: '0oahzgjyayAfQRwtd1d7',
};

const PartnerOktaAuthProd: AuthConfig = {
  issuer: 'https://partnerlogin.viasat.com/oauth2/ausojovkroBgB9X0n696',
  clientId: '0oalfp01ijcMLda6Z697',
};

const CustomerOktaAuthProd: AuthConfig = {
  issuer: 'https://login.viasat.com/oauth2/auss5o0l1DDF9mIke696',
  clientId: '0oalfogdd9gfLUuPs697',
};

const OktaStorageKey = 'okta-storage-key';
const OktaAuthPathStateKey = 'okta-auth-path-state-key';

const createOktaService = () => {
  let oktaState: OktaState = {
    isProcessing: false,
    isAuthenticated: false,
  };

  const listeners: OktaAuthListener[] = [];

  let oktaAuth: OktaAuth | undefined = undefined;

  const initialize = async (softly: boolean = false) => {
    const oktaProfile = localStorage.getItem(OktaStorageKey);
    updateState({isProcessing: true});

    if (oktaProfile === OktaProfile.Partner || oktaProfile === OktaProfile.Residential) {
      let authPathState = localStorage.getItem(OktaAuthPathStateKey);

      updateState({profile: oktaProfile, oktaAuthPathState: authPathState as OktaAuthPathState});

      if (!softly) {
        await signInWithProfile(oktaProfile);
      }
    } else {
      updateState({isAuthenticated: false});
    }

    updateState({isProcessing: false, isInitialized: true});
  };

  const clearState = (silent: boolean = false) => {
    oktaState = {
      isProcessing: false,
      isAuthenticated: false,
      isInitialized: true,
    };

    if (!silent) {
      updateState(oktaState);
    }

    localStorage.removeItem(OktaStorageKey);
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-auth-ended');
  };

  const updateState = (state: Partial<OktaState>) => {
    const newState = {...oktaState, ...state};

    for (const subscription of listeners) {
      try {
        subscription(newState, oktaState);
      } catch (e) {
        console.log('error for subscription', e);
      }
    }

    oktaState = newState;
  };

  const signInWithProfile = async (profile: OktaProfile) => {
    updateState({isProcessing: true});
    localStorage.setItem(OktaStorageKey, profile);

    setOktaAuthPathState(OktaAuthPathState.Started);

    try {
      const isDevEnv = window.location.href.includes('/installer-portal-web');
      const isLocalHost = window.location.href.includes('localhost');

      let useConfig = profile === OktaProfile.Partner ? PartnerOktaAuthProd : CustomerOktaAuthProd;
      if (isDevEnv || isLocalHost) {
        useConfig = profile === OktaProfile.Partner ? PartnerOktaAuthDev : CustomerOktaAuthDev;
      }

      let defaultScopes = ['openid', 'profile', 'email'];

      // dev envs
      const redirectUrl = window.location.origin + `${isDevEnv ? '/installer-portal-web' : ''}/login/callback`;
      const posLogoutRedirectUri = isDevEnv ? window.location.origin + `/installer-portal-web/` : undefined;

      oktaAuth = new OktaAuth({
        issuer: useConfig.issuer,
        clientId: useConfig.clientId,
        redirectUri: redirectUrl,
        postLogoutRedirectUri: posLogoutRedirectUri,

        scopes: profile === OktaProfile.Partner ? [...defaultScopes, 'vtt'] : defaultScopes,
      });

      if (!oktaState.profile) {
        await oktaAuth.signInWithRedirect();
        return;
      }

      if (window.location.href.includes('login/callback')) {
        return;
      }

      const authenticated = await oktaAuth.isAuthenticated();

      if (oktaState.oktaAuthPathState === OktaAuthPathState.Started && !authenticated) {
        console.log('okta state started but is not authenticated');
        clearState();
        window.location.href = redirectUrl;
        return;
      }

      if (authenticated) {
        const userInfo = await oktaAuth.getUser();

        updateState({
          isAuthenticated: true,
          profile: profile,
          // Use the business unit to block US Retail
          // forbidden: userInfo.business_unit === 'us_retail',
          userName: userInfo.preferred_username,
          firstName: userInfo.given_name,
          lastName: userInfo.family_name,
        });
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      updateState({isProcessing: false});
    }
  };

  const signOut = async () => {
    if (oktaAuth) {
      await oktaAuth.signOut();
      clearState();
      oktaAuth = undefined;
    } else {
      console.log('oktaAuth is not defined');
    }
  };

  const disableOkta = () => {
    updateState({isDisabled: true});
  };

  const subscribe = (listener: OktaAuthListener): OktaAuthSubscription => {
    listeners.push(listener);

    listener(oktaState, oktaState);

    return {
      remove: () => {
        const index = listeners.indexOf(listener);
        if (index !== -1) {
          listeners.splice(index, 1);
        }
      },
    };
  };

  const setOktaAuthPathState = (value: OktaAuthPathState) => {
    localStorage.setItem(OktaAuthPathStateKey, value);
    updateState({oktaAuthPathState: value});
  };

  return {
    initialize,
    signInWithProfile,
    signOut,
    subscribe,
    disableOkta,
    clearState,
    setOktaAuthPathState,

    /* @deprecated: Used only for tests*/
    _updateState: updateState,

    getOktaAuth: () => oktaAuth,
    getOktaState: () => oktaState,
  };
};

export type OktaServiceType = ReturnType<typeof createOktaService>;

export const OktaService: OktaServiceType = createOktaService();
