import React from 'react';
import {Button, Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl.macro';
import styled from 'styled-components';
import {SpaceProps, ColorProps, TypographyProps, space, color, fontSize} from 'styled-system';
import {connect} from 'react-redux';
import {restartAction} from '../../store/activation/actions';
import {useHistory} from 'react-router';

interface OwnProps {
  className?: string;
}

interface DispatchProps {
  restart: () => void;
}

type Props = OwnProps & ColorProps & SpaceProps & TypographyProps & DispatchProps;

const ForbiddenErrorPage: React.FC<Props> = ({className, restart}) => {
  return (
    <div className={className} data-test-el="authorization-error">
      <div>
        <Title variant={'h2'}>
          <FormattedMessage id="forbidden.vtt.title" defaultMessage="Installer portal is retired" />
        </Title>
        <ErrorMessage>
          <FormattedMessage id="forbidden.vtt." defaultMessage="Please use the VTT app!" />
        </ErrorMessage>
        <Button
          variant="primary"
          onClick={() => (window.location.href = 'https://eguide.field.viasat.com/viasat-tech-tools-vtt-app-americas/')}
        >
          <FormattedMessage id="forbidden.vtt.button" defaultMessage="Go to VTT App" />
        </Button>
      </div>
    </div>
  );
};

const ForbiddenErrorPagePageStyled = styled(ForbiddenErrorPage)<Props & SpaceProps>`
  ${space}
  ${color}
    ${fontSize}
    display: flex;
  align-items: center;
  justify-content: center;
`;

ForbiddenErrorPagePageStyled.defaultProps = {
  m: 5,
};

const ErrorMessage = styled.div`
  word-wrap: break-word;
  margin: ${({theme}) => theme.space[3]}px 0;
`;

export default connect(
  null,
  (dispatch): DispatchProps => ({
    restart: () => dispatch(restartAction()),
  })
)(ForbiddenErrorPagePageStyled as any);
