import React from 'react';
import {OktaService, OktaState} from '../../OktaService';

export const useOktaService = () => {
  const [oktaState, setOktaState] = React.useState<OktaState>({
    isAuthenticated: false,
    isProcessing: false,
  });

  React.useEffect(() => {
    const subscription = OktaService.subscribe((newState: OktaState) => {
      setOktaState(newState);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  return oktaState;
};
