import {
  receiveInitApp,
  changeLocale,
  queueSnackMessage,
  clearSnackMessage,
  closeSnackMessage,
  setInstallationTypeAction,
} from './actions';
import {reducerWithInitialState} from 'typescript-fsa-reducers';
import env from '../../utils/env';
import {InstallationSubType, InstallationTypesCodes} from '../../definitions/types';

export interface ISystemState {
  readonly locale: string;
  readonly installationType: string;
  readonly country: string;
  readonly defaultLocale: string;
  readonly snackMessages: SnackMessage[];
  readonly installationSubtype: InstallationSubType;
}

export interface SnackMessage {
  key?: string;
  message: string;
  options: any;
  dismissed?: boolean;
}

export const initialState: ISystemState = {
  defaultLocale: env.defaultLocale as any,
  locale: env.defaultLocale as any,
  snackMessages: [],
  installationType: InstallationTypesCodes.RES,
  country: 'US',
  installationSubtype: InstallationSubType.UPGRADE,
};

export default reducerWithInitialState(initialState)
  .case(receiveInitApp, (state, {locale, installationType, country, installationSubtype}): ISystemState => {
    return {
      ...initialState,
      locale,
      installationType,
      installationSubtype,
      country,
    };
  })
  .case(
    setInstallationTypeAction.done,
    (state, {params}): ISystemState => ({
      ...state,
      installationType: params.installationType,
      installationSubtype: params.installationSubtype,
      country: params.country,
    })
  )
  .case(changeLocale.done, (state, {params}): ISystemState => {
    return {
      ...state,
      locale: params.locale,
    };
  })
  .case(queueSnackMessage, (state, snackMessage): ISystemState => {
    const message: SnackMessage = {
      key: (new Date().getTime() + Math.random()).toString(),
      ...snackMessage,
    };

    return {
      ...state,
      snackMessages: [...state.snackMessages, message],
    };
  })
  .case(clearSnackMessage, (state, key): ISystemState => {
    return {
      ...state,
      snackMessages: state.snackMessages.filter((m: SnackMessage) => (m.key || '') !== key),
    };
  })
  .case(closeSnackMessage, (state, key): ISystemState => {
    return {
      ...state,
      snackMessages: state.snackMessages.map((message: SnackMessage) =>
        message.key === key ? {...message, dismissed: true} : {...message}
      ),
    };
  });
