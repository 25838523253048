import React, {useEffect, useState} from 'react';

import ButtonContainer from '../../../common/ButtonContainer';
import {FormattedMessage} from 'react-intl';
import RestartButton from '../../../common/RestartButton';
import {macAddressRegex, serviceActivationCodeRegex, siteIdRegex} from '../../../util/regex';
import MacAddressValidationError from '../../../../components-lib/form/MacAddressValidationError';
import {Button, Card, Text, TextField, ValidatorForm} from '@viasat/components';
import {getQueryParams} from '../../../util/appUtilities';
import {InstallationSubType, InstallationTypesCodes} from '../../../../definitions/types';
import {useInstallationType} from './useInstallationType';

interface Props {
  onSubmit: (
    serviceActivationCode: string,
    installerId: string,
    macAddress: string,
    siteId: string,
    dealerId: string
  ) => void;
  isBusy: boolean;
  installationType: InstallationTypesCodes;
  installationSubtype: InstallationSubType;
}

export const AuthorizationForm: React.FC<Props> = ({onSubmit, isBusy, installationSubtype, installationType}) => {
  const [validationErrors, setValidationError] = useState([]);
  const [installerIdInput, setInstallerId] = useState('');

  const [dealerIdInput, setDealerId] = useState('');
  const [siteId, setSiteId] = useState('');
  const [macAddressInput, setMacAddress] = useState('');
  const [serviceActivationCodeInput, setServiceActivationCode] = useState('');
  const {
    isVci,
    isWholeSale,
    isVoIP,
    isResidential,
    isResSwap,
    isPartnerVci,
    isResidentialUpgrade,
    isVciSwap,
    isWholeSaleSwap,
  } = useInstallationType(installationSubtype, installationType);

  const hasError = (fieldId: string): boolean =>
    validationErrors.filter((e: {props: {id: string}}) => e.props.id === fieldId).length > 0;

  useEffect(() => {
    if (isResidential) {
      const {macAddress, serviceActivationCode, installerId} = getQueryParams();
      macAddress && setMacAddress(macAddress);
      serviceActivationCode && setServiceActivationCode(serviceActivationCode);
      installerId && setInstallerId(installerId);
    }
  }, [isResidential]);
  useEffect(() => {
    if (isWholeSaleSwap) {
      const {serviceActivationCode} = getQueryParams();
      serviceActivationCode && setServiceActivationCode(serviceActivationCode);
    }
  }, [isWholeSaleSwap]);

  return (
    <ValidatorForm
      onSubmit={() => onSubmit(serviceActivationCodeInput, installerIdInput, macAddressInput, siteId, dealerIdInput)}
      onError={(errors) => setValidationError(errors)}
    >
      <Card>
        <Text textAlign="center" mt={3} mb={4} display="block">
          {isVci ? (
            <FormattedMessage
              id="vci.service.activate.instructions"
              defaultMessage="To begin service activation, please populate all fields."
            />
          ) : (
            <FormattedMessage
              id="service.activate.instructions"
              defaultMessage="To begin please populate the fields below"
            />
          )}
        </Text>
        {!isPartnerVci && !isVciSwap && (
          <TextField
            id="serviceactivationcode"
            fullWidth={true}
            label={
              <FormattedMessage
                id="service.activate.serviceactivationcode.label"
                defaultMessage="Service Activation Code or Work Order ID"
              />
            }
            onChange={({target}) => {
              setValidationError([]);
              setServiceActivationCode(target.value.trim());
            }}
            value={serviceActivationCodeInput}
            mt={1}
            mb={3}
            useValidator={true}
            validators={[
              ...(!(isWholeSale || isResidentialUpgrade) ? [`matchRegexp:${serviceActivationCodeRegex}`] : []),
              'required',
            ]}
            error={hasError('serviceactivationcode')}
            errorMessages={[
              ...(!(isWholeSale || isResidentialUpgrade)
                ? [
                    <FormattedMessage
                      id="invalid.satelliteActivationCode"
                      defaultMessage="A Service Activation Code must contain 8 or 9 digits"
                    />,
                  ]
                : []),
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
            ]}
          />
        )}
        {isVci && (
          <TextField
            id="siteid"
            fullWidth={true}
            label={<FormattedMessage id="vci.details.validate.siteid.label" defaultMessage="Site ID" />}
            value={siteId}
            onChange={({target}) => {
              setValidationError([]);
              setSiteId(target.value.trim());
            }}
            mt={1}
            mb={3}
            useValidator={true}
            validators={['required', `matchRegexp:${siteIdRegex}`]}
            error={hasError('siteid')}
            errorMessages={[
              <FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />,
              <FormattedMessage
                id="vci.invalid.siteId"
                defaultMessage="A site id must contain no more than 9 digits"
              />,
            ]}
          />
        )}
        {isWholeSaleSwap && (
          <TextField
            id="dealerId"
            fullWidth={true}
            label={<FormattedMessage id="details.validate.dealerId.label" defaultMessage="Dealer ID" />}
            value={dealerIdInput}
            onChange={({target}) => {
              setValidationError([]);
              setDealerId(target.value.trim());
            }}
            mt={1}
            mb={3}
            useValidator={true}
            validators={['required']}
            error={hasError('dealerId')}
            errorMessages={[<FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />]}
          />
        )}
        {!isWholeSaleSwap && (
          <TextField
            id="installerid"
            fullWidth={true}
            label={<FormattedMessage id="details.validate.installerid.label" defaultMessage="Installer ID" />}
            value={installerIdInput}
            onChange={({target}) => {
              setValidationError([]);
              setInstallerId(target.value.trim());
            }}
            mt={1}
            mb={3}
            useValidator={true}
            validators={['required']}
            error={hasError('installerid')}
            errorMessages={[<FormattedMessage id="form.mandatory.label" defaultMessage="Please enter a value" />]}
          />
        )}
        {!isVoIP && !isVciSwap && !isResSwap && !isWholeSaleSwap && (
          <TextField
            id="macaddress"
            fullWidth={true}
            label={<FormattedMessage id="service.activate.macaddress.label" defaultMessage="Modem MAC Address" />}
            value={macAddressInput}
            onChange={({target}) => {
              setValidationError([]);
              setMacAddress(target.value.trim());
            }}
            useValidator={true}
            validators={[`matchRegexp:${macAddressRegex}`]}
            error={hasError('macaddress')}
            errorMessages={[<MacAddressValidationError />]}
            mt={0}
            mb={1}
          />
        )}
      </Card>
      <ButtonContainer>
        <RestartButton />
        <Button type="submit" disabled={isBusy}>
          <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
        </Button>
      </ButtonContainer>
    </ValidatorForm>
  );
};
