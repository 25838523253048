import {CountryCodes, ICountries, IInstallationTypes, InstallationSubType, InstallationTypesCodes} from './types';

export enum QoiCategoryName {
  SOFTWARE_VERSION = 'Software Version',
  EQUIPMENT = 'Equipment (Cable & ODU)',
  ANTENNA_AFTER_BURNER = 'Antenna Pointing - AfterBurner',
  ANTENNA_SURFBEAM = 'Antenna Pointing',
  INSTALLED_DEVICES = 'Installed Devices',
  PEAKING = 'Peaking',
}
export const qoiActivationSteps = [
  [QoiCategoryName.SOFTWARE_VERSION as string],
  [QoiCategoryName.EQUIPMENT as string],
  [QoiCategoryName.ANTENNA_AFTER_BURNER as string, QoiCategoryName.ANTENNA_SURFBEAM as string],
  [QoiCategoryName.INSTALLED_DEVICES as string],
];
export const installationSubTypes = [
  {
    name: 'Satellite Internet / Upgrade',
    code: InstallationSubType.SATELITE_INTERNET,
    key: 'installation.satelite.internet',
  },
  {name: 'SB2 VoIP', code: InstallationSubType.VOIP, key: 'installation.voip'},
  {name: 'Wholesale', code: InstallationSubType.WHOLESALE, key: 'installation.wholesale'},
  {name: 'Africa', code: InstallationSubType.PARTNER_VCI, key: 'installation.partnerVci'},
  {name: 'SB2 to SB2+', code: InstallationSubType.UPGRADE, key: 'installation.upgrade'},
  {
    name: 'Latin America',
    code: InstallationSubType.VCI_SATELITE_INTERNET,
    key: 'installation.vci.satelite.internet',
  },
  {name: 'Equipment Swap', code: InstallationSubType.VCI_SWAP, key: 'installation.swap'},
  {name: 'Service Call Swap', code: InstallationSubType.RES_SWAP, key: 'installation.ServiceCallSwap'},
  {name: 'Service Call Swap', code: InstallationSubType.WHOLESALE_SWAP, key: 'installation.wholeSaleSwap'},
];
export const installationTypes: IInstallationTypes[] = [
  {
    name: 'Residential',
    code: InstallationTypesCodes.RES,
    key: 'installation.residential',
    subtypes: [
      installationSubTypes[0],
      // Disabled as part of IP-4857 - Remove US residential install options
      // installationSubTypes[1],
      // installationSubTypes[2],
      installationSubTypes[4],
      installationSubTypes[7],
    ],
  },
  // Disabled as part of IP-4857 - Remove US residential install options
  // {
  //   name: 'Global Business Solutions',
  //   code: InstallationTypesCodes.WHOLESALE,
  //   key: 'installation.wholesale',
  //   subtypes: [installationSubTypes[0], installationSubTypes[8]],
  // },
  {
    name: 'Viasat Community Internet',
    code: InstallationTypesCodes.VCI,
    key: 'installation.vci',
    subtypes: [installationSubTypes[5], installationSubTypes[3], installationSubTypes[6]],
    // },
    // {
    //   name: 'Global Business Solutions',
    //   code: InstallationTypesCodes.GBS,
    //   key: 'installation.gbs',
    //   subtypes: [installationSubTypes[0], installationSubTypes[1]]
  },
];

export const countries: ICountries[] = [
  {
    name: 'United States',
    code: CountryCodes.US,
    key: 'country.us',
    installationCodes: [InstallationTypesCodes.RES, InstallationTypesCodes.GBS],
    needsCreditCardValidation: true,
    needsECA: [InstallationSubType.SATELITE_INTERNET],
    installationSubtypeCodes: [
      // Disabled as part of IP-4857 - Remove US residential install options
      // InstallationSubType.SATELITE_INTERNET,
      // InstallationSubType.VOIP,
      // InstallationSubType.WHOLESALE,
      InstallationSubType.UPGRADE,
      // InstallationSubType.RES_SWAP,
    ],
  },
  // {
  //   name: 'Brazil',
  //   code: CountryCodes.BR,
  //   key: 'country.br',
  //   needsECA: [],
  //   installationCodes: [InstallationTypesCodes.RES, InstallationTypesCodes.VCI, InstallationTypesCodes.WHOLESALE],
  //   installationSubtypeCodes: [
  //     InstallationSubType.SATELITE_INTERNET,
  //     InstallationSubType.RES_SWAP,
  //     InstallationSubType.VCI_SATELITE_INTERNET,
  //     InstallationSubType.VCI_SWAP,
  //     InstallationSubType.WHOLESALE_SWAP,
  //   ],
  // },
  {
    name: 'Guatemala',
    code: CountryCodes.GT,
    key: 'country.gt',
    needsECA: [],
    installationCodes: [InstallationTypesCodes.VCI],
    installationSubtypeCodes: [InstallationSubType.VCI_SATELITE_INTERNET, InstallationSubType.VCI_SWAP],
  },
  {
    name: 'Mexico',
    code: CountryCodes.MX,
    key: 'country.mx',
    needsECA: [],
    installationCodes: [InstallationTypesCodes.RES, InstallationTypesCodes.VCI],
    installationSubtypeCodes: [
      InstallationSubType.SATELITE_INTERNET,
      InstallationSubType.VCI_SATELITE_INTERNET,
      InstallationSubType.VCI_SWAP,
    ],
  },
  {
    name: 'Mexico CFE',
    code: CountryCodes.MXCFE,
    key: 'country.mxcfe',
    needsECA: [],
    installationCodes: [InstallationTypesCodes.RES],
    installationSubtypeCodes: [
      InstallationSubType.SATELITE_INTERNET,
      InstallationSubType.RES_SWAP,
      InstallationSubType.UPGRADE,
    ],
  },
  {
    name: 'Nigeria',
    code: CountryCodes.NG,
    key: 'country.ng',
    needsECA: [],
    installationCodes: [InstallationTypesCodes.VCI],
    installationSubtypeCodes: [InstallationSubType.PARTNER_VCI, InstallationSubType.VCI_SWAP],
  },
];
