import React, {useEffect} from 'react';
import {OktaAuthPathState, OktaProfile, OktaService} from '../../../OktaService';
import {PageContainer} from '../../common/AppLayout';
import {Box, Button, Card, LoaderComponent, Text, Title} from '@viasat/components';
import {FormattedMessage} from 'react-intl';
import ButtonContainer from '../../common/ButtonContainer';
import {RadioBox, RadioBoxGroup} from '../../../shared-components/RadioBox';
import {FormControlLabel} from '@material-ui/core';
import {useOktaService} from '../../util/useOktaService';

const ENABLE_OKTA_FOR_TECHS = true;

const OnboardScreenFC: React.FC = () => {
  const [selectedProfile, setSelectedProfile] = React.useState<OktaProfile>();
  const oktaState = useOktaService();

  useEffect(() => {
    if (!oktaState.isProcessing && oktaState.profile && oktaState.oktaAuthPathState === OktaAuthPathState.Started) {
      OktaService.clearState(true);
    }
  }, [oktaState.profile, oktaState.oktaAuthPathState, oktaState.isInitialized, oktaState.isProcessing]);

  if (oktaState.isProcessing || oktaState.isAuthenticated || !oktaState.isInitialized) {
    return <LoaderComponent />;
  }

  return (
    <>
      <PageContainer data-test-el="landing-page">
        <Text textAlign="center" mt={1} mb={2} display="block" data-test-el="installation-type-header">
          <Title>
            <FormattedMessage id="landingpage.title" defaultMessage="Welcome to Service Activation" />
          </Title>
        </Text>
        <Card>
          <Box display="flex" flexDirection="column">
            <Text textAlign="center" mt={1} mb={2} display="block" data-test-el="installation-type-header">
              <FormattedMessage id="onboardpage.header" defaultMessage="I'm visiting as:" />
            </Text>
            <RadioBoxGroup
              aria-label="installedResEquipment"
              name="installedResEquipment"
              value={selectedProfile}
              onChange={(_, value) => setSelectedProfile(value as OktaProfile)}
            >
              <FormControlLabel key={1} value={OktaProfile.Residential} control={<RadioBox />} label={'Customer'} />
              <FormControlLabel key={2} value={OktaProfile.Partner} control={<RadioBox />} label={'Technician'} />
            </RadioBoxGroup>
          </Box>
        </Card>
        {selectedProfile === OktaProfile.Residential && (
          <Text textAlign="center" mt={1} mb={2} display="block" data-test-el="installation-type-header">
            <FormattedMessage
              id="onboardpage.customerInfo"
              defaultMessage="After clicking Continue, you’ll be asked to log into your My Viasat account. If you don’t have a My Viasat account, you can create one on the next page"
            />
          </Text>
        )}
        <ButtonContainer>
          <Button
            disabled={!selectedProfile}
            onClick={() => {
              if (!ENABLE_OKTA_FOR_TECHS && selectedProfile === OktaProfile.Partner) {
                OktaService.disableOkta();
              } else {
                OktaService.signInWithProfile(selectedProfile!);
              }
            }}
            data-test-el="continue-button"
          >
            <FormattedMessage id="page.continue.button" defaultMessage="Continue" />
          </Button>
        </ButtonContainer>
      </PageContainer>
    </>
  );
};

export const OnboardScreen = OnboardScreenFC;
