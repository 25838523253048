import React, {useEffect} from 'react';
import ConnectedLocaleSwitcher from './components/common/ConnectedLocaleSwitcher';
import SystemNotifier from './components/common/SystemNotifier';
import {AppContainer, BodyContainer} from './components/common/AppLayout';
import {BrowserRouter, Switch} from 'react-router-dom';
import logoImage from './images/viasat-logo.svg';
import logoImageWhite from './images/viasat-white-logo.svg';
import PageFactoryContainer from './components/pages/PageFactoryContainer';
import {FormattedMessage} from 'react-intl.macro';
import {connect} from 'react-redux';
import {IRootState} from './store';
import env from './utils/env';
import {FlowState} from './definitions/types';
import {Button, ExternalLink, Footer, Header, LoaderComponent, Text} from '@viasat/components';
import {Route} from 'react-router';
import {OktaAuthPathState, OktaService, OktaState} from './OktaService';
import {LoginCallback, SecureRoute, Security} from '@okta/okta-react';
import OktaAuth from '@okta/okta-auth-js';
import styled from 'styled-components';
import {OnboardScreen} from './components/pages/onboarding/OnboardScreen';

export interface Props {
  flowState?: FlowState;
}

const App: React.FC<Props> = ({flowState}) => {
  const [oktaState, setOktaState] = React.useState<OktaState>(OktaService.getOktaState());

  useEffect(() => {
    OktaService.initialize().then(() => {});

    const subscription = OktaService.subscribe((newState: OktaState) => {
      setOktaState(newState);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  const oktaAuth = OktaService.getOktaAuth();
  const isDevEnv = window.location.href.includes('/installer-portal-web');

  const redirectToContent = async (_oktaAuth: OktaAuth, _originalUrl: string) => {
    OktaService.setOktaAuthPathState(OktaAuthPathState.Ended);

    window.location.href = `${window.location.origin}/${isDevEnv ? 'installer-portal-web/' : ''}`;
  };

  return (
    <AppContainer>
      <BodyContainer>
        <Header
          logoImage={logoImage}
          actions={
            flowState === FlowState.NotAuthorized && (
              <UserControls>
                <ConnectedLocaleSwitcher />
                {oktaState.profile && (
                  <LogoutButton
                    variant="primary"
                    size={'small'}
                    onClick={() => {
                      OktaService.signOut();
                    }}
                  >
                    <FormattedMessage id="logout" defaultMessage="Logout" />
                  </LogoutButton>
                )}
              </UserControls>
            )
          }
        />
        <BrowserRouter basename={env.contextPath ? env.contextPath : undefined}>
          {oktaAuth && (
            <Security
              oktaAuth={oktaAuth!}
              restoreOriginalUri={redirectToContent}
              onAuthRequired={() => {
                // we override the redirect to the sign in page
              }}
            >
              <Switch>
                <Route
                  path={`${isDevEnv ? '/installer-portal-web' : ''}/login/callback`}
                  component={() => (
                    <>
                      <LoaderComponent />
                      <LoginCallback />
                    </>
                  )}
                />
                <SecureRoute path="*" component={(props: any) => <PageFactoryContainer {...props} />} />
              </Switch>
            </Security>
          )}
          {!oktaAuth && oktaState.isInitialized && (
            <Switch>
              <Route path="*" component={(props: any) => <OnboardScreen {...props} />} />
            </Switch>
          )}
        </BrowserRouter>
        <Footer
          logoImage={logoImageWhite}
          content={
            <React.Fragment>
              <Text ml={0} mr={3} variant="secondary" fontSize={[0, 1, 2, 2, 2]}>
                <FormattedMessage id="footer.copyright" defaultMessage="©2025 All rights reserved" />
              </Text>
              |
              <ExternalLink
                to="https://www.viasat.com/legal"
                variant="secondary"
                ml={3}
                mr={3}
                fontSize={[0, 1, 2, 2, 2]}
              >
                <FormattedMessage id="footer.legal" defaultMessage="Legal" />
              </ExternalLink>
            </React.Fragment>
          }
          infoMessage={<FormattedMessage id="footer.infomessage" defaultMessage="Service Activation" />}
        />
      </BodyContainer>
      <SystemNotifier />
    </AppContainer>
  );
};

export default connect(
  (state: IRootState): Props => ({
    flowState: state.activation.applicationFlowState,
  }),
  () => ({})
)(App as any);

const UserControls = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LogoutButton = styled(Button)`
  font-size: 12px;
  width: 20px;
`;
