import React, {Suspense} from 'react';
import {connect} from 'react-redux';
import {IRootState} from '../../store';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import AuthorizationErrorPage from './AuthorizationErrorPage';
import LandingPage from './landingPage/LandingPage';
import {ApplicationFlow} from './ApplicationFlows';
import {LoaderComponent} from '@viasat/components';
import {
  ApplicationFlowStep,
  ApplicationFlowStepMap,
  FlowState,
  IActivationState,
  InstallationSubType,
  InstallationTypesCodes,
} from '../../definitions/types';
import {OktaState} from '../../OktaService';
import ForbiddenErrorPage from './ForbiddenErrorPage';
import {useOktaService} from '../util/useOktaService';
import {OnboardScreen} from './onboarding/OnboardScreen';

const INITIAL_FLOW_STEP = 'Authorization';

export interface StateProps {
  activation: IActivationState;
  installationType: string;
  installationSubtype: InstallationSubType;
}

export type Props = StateProps;

const getCurrentFlowStep = (flowMap: ApplicationFlowStepMap, state: IActivationState) => {
  let currentStep = flowMap[INITIAL_FLOW_STEP];
  if (state.applicationFlowState) {
    Object.values(flowMap).forEach((step: ApplicationFlowStep) => {
      if (step.guard(state)) {
        currentStep = step;
      }
    });
  }

  return currentStep;
};

const getPageComponent = (
  activation: IActivationState,
  installationType: string,
  installationSubtype: InstallationSubType,
  oktaState: OktaState
) => {
  if (!oktaState.isDisabled) {
    if (!oktaState.isInitialized) {
      return <LoaderComponent />;
    }

    if (!oktaState.isAuthenticated) {
      return <OnboardScreen />;
    }

    if (oktaState.forbidden) {
      return <ForbiddenErrorPage />;
    }
  }

  const installationTypeSelected = activation.installationTypeSelected;

  if (!installationTypeSelected) {
    return <LandingPage />;
  }

  const sessionExpired =
    activation.applicationFlowState !== FlowState.NotAuthorized && activation.error && activation.error.status === 401;
  if (sessionExpired) {
    return <AuthorizationErrorPage />;
  }

  const applicationFlowType =
    ApplicationFlow[installationType][installationSubtype] ||
    ApplicationFlow[InstallationTypesCodes.RES][InstallationSubType.SATELITE_INTERNET];
  const CurrentComponent = getCurrentFlowStep(applicationFlowType, activation).component;

  return <CurrentComponent />;
};

const PageFactoryContainer: React.FC<Props> = ({activation, installationType, installationSubtype}) => {
  const oktaService = useOktaService();

  return activation !== undefined ? (
    <Suspense fallback={<LoaderComponent />}>
      {getPageComponent(activation, installationType, installationSubtype, oktaService)}
    </Suspense>
  ) : (
    <LoaderComponent />
  );
};

export default connect(
  (state: IRootState) => ({
    activation: state.activation,
    installationType: state.system.installationType,
    installationSubtype: state.system.installationSubtype,
  }),
  (dispatch: ThunkDispatch<IRootState, {}, AnyAction>): any => ({})
)(PageFactoryContainer);
